import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import AspectFittedImage from "../components/aspectFittedImage.js"
import { theme } from '../../config/website'
import SEO from "../components/SEO/SEO"

const Container = styled.div`
  a {
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.primaryColor};
    font-family: Edmondsans-Medium;

    :hover, :active {
      color: black;
      text-decoration: none;
    }
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 16px;

  @media (min-width: 1024px) {
    grid-column-gap: 48px;
    grid-row-gap: 40px;
    grid-template-columns: 6.5fr 3.5fr;
    margin: 0px 100px;
  }

  @media (min-width: 700px) and (max-width: 1024px) {
    grid-column-gap: 48px;
    grid-row-gap: 40px;
    grid-template-columns: 6.5fr 3.5fr;
    margin: 0px;
  }
`

const FeaturedImageWrapper = styled.div`
  background: #f5f5f5;
  display: flex;
  height: 100%;

  ::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(3/4 * 100%);
  }
`

const FeaturedImage = styled(AspectFittedImage)`
`

const ImageList = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  div:last-child {
    margin-bottom: 0px;
  }
`

const Image = styled(GatsbyImage)`
  margin-bottom: 40px;
`

const TextContainer = styled.div`
  @media (min-width: 768px) {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`

const Title = styled.h1`
  margin-top: 32px;
  margin-bottom: 8px;
  font-family: 'Edmondsans-Medium';

  @media (max-width: 768px) {
    margin-top: 8px;
  }
`

const Content = styled.div`
  p:last-child {
    margin: 2px 0px;
  }
`

const Size = styled.div`
  margin-bottom: 16px;
`

const Work = ({ data: { prismicWorkdetail } }) => {
  const data = {
    featuredImage: prismicWorkdetail.data.featured_image.localFile.childImageSharp.gatsbyImageData,
    additionalImages: prismicWorkdetail.data.additional_images
      .filter(item => item.image.localFile != null)
      .map(item => item.image.localFile.childImageSharp.gatsbyImageData),
    titleText: prismicWorkdetail.data.title.text,
    contentHTML: prismicWorkdetail.data.content.html,
    sizeText: `${prismicWorkdetail.data.height.text} x ${prismicWorkdetail.data.width.text} in`
  }

  const seo = {
    title: data.titleText,
    uid: prismicWorkdetail.uid,
    image: prismicWorkdetail.data.featured_image.localFile.publicURL
  }

  return (
    <Container>
      <SEO meta={seo} isWork />
      <FeaturedImageWrapper>
        <FeaturedImage image={data.featuredImage} />
      </FeaturedImageWrapper>
      <TextContainer>
        <Title>{data.titleText}</Title>
        <Content dangerouslySetInnerHTML={{ __html: data.contentHTML }} />
        <Size>{data.sizeText}</Size>
      </TextContainer>
      <ImageList>
        {data.additionalImages.map((image, index) => 
          <Image key={index} image={image} alt="" />
        )}
      </ImageList>
    </Container>
  )
}

export default Work

export const pageQuery = graphql`
  query WorkdetailBySlug($uid: String!) {
    prismicWorkdetail(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        width {
          text
        }
        height {
          text
        }
        content {
          html
        }
        featured_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1024
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
        additional_images {
          image {
            localFile {
              childImageSharp {
                  gatsbyImageData(
                      width: 1024
                      placeholder: NONE
                      formats: [AUTO, WEBP, AVIF]
                  )
              }
            }
          }
        }
      }
    }
  }
`